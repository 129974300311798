import { MessageTypeConstants } from "../../../../Services/Message";
import _ from "lodash";
import { showSnackbarV2 } from "../../../Store/Notification/NotificationAction";
import store from "../../../../State/configureStore";

export const handleMessageTypeTableChangeAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		// check which item in app it want to update
		NonConversationalData.components.forEach((item) => {
			// check if it's in a component like container
			if (
				(item?.options?.docId &&
					message?.options?.parentDocId &&
					item?.options?.docId === message?.options?.parentDocId) ||
				message?.options?.parent
			) {
				if (
					item?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
							item.message.forEach((msg) => {
								if (
									message?.options &&
									msg?.message?.options?.tableId === message?.options?.tableId
								) {
									if (message?.message?.deleteRow) {
										// remove the row from table
										msg.message.rows = msg.message.rows?.filter(
											(field) =>
												!message.message.deleteRow.includes(
													Object.keys(field)[0]
												)
										);
									} else if (message?.message?.update) {
										msg.message.rows = message.message.update;
									} else if (message?.message?.remove) {
										// remove the fields from the rows
										msg.message.rows = msg.message.rows?.map(
											([docId, rowValue]) => {
												if (docId === message.options.docId) {
													return rowValue.map(
														(field) =>
															!message.message.remove.includes(field.id)
													);
												}
											}
										);
									} else if (message?.message?.append) {
										msg.message.rows = msg.message.rows.concat(
											message.message.append
										);
									} else if (message?.message?.fields) {
										msg.message.rows.map((row) =>
											Object.entries(row).map(([docId, rowValue]) => {
												if (
													message?.options?.docId &&
													docId &&
													message?.options?.docId === docId
												) {
													for (
														let i = 0;
														i < message.message.fields.length;
														i++
													) {
														const newFiled = message.message.fields[i];
														// Updating existing field
														let fieldsList = [];

														rowValue.fields = rowValue.fields.map((field) => {
															fieldsList.push(field.id);
															if (field.id === newFiled.id) {
																field = newFiled;
															}
															return field;
														});

														// Incase new filed is added
														if (!fieldsList.includes(newFiled.id)) {
															rowValue.fields = [...rowValue.fields, newFiled];
															// Sort if index is present
															if (newFiled.index) {
																rowValue.fields = rowValue.fields.sort((a, b) =>
																	a.index > b.index
																		? 1
																		: b.index > a.index
																		? -1
																		: 0
																);
															}
														}
													}

													if (!_.isEmpty(message.options.rowOptions)) {
														rowValue.rowOptions = message.options.rowOptions;
													}
												}
											})
										);
									}
									msg.message.options.pages = message.options.pages;
								}
							});
							break;
					}
					item.options.pages = message.options.pages;
				}
				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined) {
					item.options.promptOnClose = promptOnClose;
				}
			} else {
				// if it's independent form
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					let newFields = message.message.fields;
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
							if (message?.message?.update) {
								newFields = message?.message?.update;
							} else if (message?.message?.append) {
								newFields = item.message.concat(message.message.append);
							} else if (message?.message?.fields) {
								newFields = message?.message?.fields;
							}
							item.options.view =
								item.options.view || message?.options?.view || "month";
							item.options.style =
								item.options.style || message?.options?.style || "calendar";
							item.options.openDrawer = item.options.openDrawer || false;
							item.options.showFilterSearchInput =
								item.options.showFilterSearchInput || false;
							item.options.isNewFilter = item.options.isNewFilter || false;
							item.message = newFields;
							break;
						case MessageTypeConstants.MESSAGE_TYPE_FORM2:
						case MessageTypeConstants.MESSAGE_TYPE_TABLE:
						case MessageTypeConstants.MESSAGE_TYPE_MAP:
							if (message?.message?.deleteRow) {
								// remove the row from table
								item.message = item.message?.filter(
									(row) =>
										!message.message.deleteRow.includes(Object.keys(row)[0])
								);
							} else if (message?.message?.update) {
								item.message = message?.message?.update;
							} else if (message?.message?.append) {
								item.message = item.message.concat(message.message.append);
							} else if (message?.message?.fields) {
								item.message.map((row) =>
									Object.entries(row).map(([docId, rowValue]) => {
										if (
											message?.options?.docId &&
											docId &&
											message?.options?.docId === docId
										) {
											for (let i = 0; i < message.message.fields.length; i++) {
												const newFiled = message.message.fields[i];
												// Updating existing field
												let fieldsList = [];

												rowValue.fields = rowValue.fields.map((field) => {
													fieldsList.push(field.id);
													if (field.id === newFiled.id) {
														field = newFiled;
													}
													return field;
												});

												// Incase new filed is added
												if (!fieldsList.includes(newFiled.id)) {
													rowValue.fields = [...rowValue.fields, newFiled];
													// Sort if index is present
													if (newFiled.index) {
														rowValue.fields = rowValue.fields.sort((a, b) =>
															a.index > b.index ? 1 : b.index > a.index ? -1 : 0
														);
													}
												}
											}

											if (!_.isEmpty(message.options.rowOptions)) {
												rowValue.rowOptions = message.options.rowOptions;
											}
										}
									})
								);
							}

							break;
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined) {
						item.options.promptOnClose = promptOnClose;
					}
					item.options.pages = message.options.pages;
				}
			}
		});
	}
	return NonConversationalData;
};

export const handleMessageTypeInlineFormTableResultAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		NonConversationalData.components.forEach((item) => {
			//message has a parent case handling
			if (
				item?.options?.docId &&
				message?.options?.parentDocId &&
				item?.options?.docId === message?.options?.parentDocId
			) {
				if (message.message.field) {
					item.message.map((msg) => {
						if (msg?.message?.options?.tableId === message?.options?.tableId) {
							msg.message.rows.map((row) => {
								Object.entries(row).map(([docId, fieldsObject]) => {
									if (message?.options?.docId === docId) {
										fieldsObject.fields.map((field) => {
											if (field.id === message?.message?.field) {
												field.results = message?.message?.results;
												field.validationResult =
													message?.message?.validationResult;
												field.validationMessage =
													message?.message?.validationMessage;
											}
											return field;
										});
									}
								});
							});
						}
					});
				} else {
					item.genericError = message.message;
					if (!message.message.validationResult) {
						store.dispatch(
							showSnackbarV2("error", message.message.validationMessage)
						);
					}
				}

				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			} else {
				if (message.message.field) {
					if (item?.options?.tableId === message?.options?.tableId) {
						Array.isArray(item.message) &&
							item.message.map((row) => {
								Object.entries(row).map(([docId, fieldsObject]) => {
									if (message?.options?.docId === docId) {
										fieldsObject.fields.map((field) => {
											if (field.id === message?.message?.field) {
												field.results = message?.message?.results;
												field.validationResult =
													message?.message?.validationResult;
												field.validationMessage =
													message?.message?.validationMessage;
											}
											return field;
										});
									}
								});
							});
					}
				} else {
					item.genericError = message.message;
					if (!message.message.validationResult) {
						store.dispatch(
							showSnackbarV2("error", message.message.validationMessage)
						);
					}
				}

				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			}
		});
		return NonConversationalData;
	}
};

export const handleMessageTypeTableChangeColumnTemplate = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		// check which item in app it want to update
		NonConversationalData.components.forEach((item) => {
			// check if it's in a component like container
			if (
				item?.options?.docId &&
				message?.options?.parentDocId &&
				item?.options?.docId === message?.options?.parentDocId
			) {
				if (
					item?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
							item.message.forEach((msg) => {
								if (
									message?.options &&
									msg?.message?.options?.tableId === message?.options?.tableId
								) {
									if (message.options.columnTemplate) {
										msg.message.options.columnTemplate =
											message.options.columnTemplate;
									}
								}
							});
							break;
					}
				}
				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			} else {
				// if it's independent form
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
						case MessageTypeConstants.MESSAGE_TYPE_FORM2:
						case MessageTypeConstants.MESSAGE_TYPE_TABLE:
							if (message.options.columnTemplate) {
								item.options.columnTemplate = message.options.columnTemplate;
							}
							break;
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined)
						item.options.promptOnClose = promptOnClose;
				}
			}
		});
	}
	return NonConversationalData;
};

export const handleMessageTypeForm2ChangeAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		// check which item in app it want to update
		NonConversationalData.components.forEach((item) => {
			// check if it's in a component like container
			if (message?.options?.parent) {
				if (
					item?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
							item.message.forEach((msg) => {
								if (
									message?.options &&
									msg?.message?.options?.formId === message?.options?.formId
								) {
									if (message?.message?.remove) {
										msg.message.fields = msg.message.fields?.filter(
											(field) => !message.message.remove.includes(field.id)
										);
									} else if (message?.message?.update) {
										msg.message.fields = msg.message.fields?.map((field) =>
											message.message.update.id === field.id
												? message.message.update
												: field
										);
									} else if (message?.message?.fields) {
										for (let i = 0; i < message.message.fields.length; i++) {
											const newFiled = message.message.fields[i];
											let fieldsList = [];
											// Update the fileds
											msg.message.fields = msg.message.fields?.map((field) => {
												fieldsList.push(field.id);
												if (field.id === newFiled.id) {
													field = newFiled;
												}
												return field;
											});

											// Incase new filed is added
											if (!fieldsList.includes(newFiled.id)) {
												msg.message.fields = [...msg.message.fields, newFiled];
												// Sort if index is present
												if (newFiled.index) {
													msg.message.fields = msg.message.fields.sort((a, b) =>
														a.index > b.index ? 1 : b.index > a.index ? -1 : 0
													);
												}
											}
										}
									}
								}
							});
							break;
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined)
						item.options.promptOnClose = promptOnClose;
				}
			} else {
				// if it's independent form
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_FORM2: {
							let newFields = message?.message?.fields;

							if (message?.message?.update) {
								newFields = message?.message?.update;
							} else if (message?.message?.fields) {
								newFields = message?.message?.fields;
							}

							for (let i = 0; i < newFields.length; i++) {
								let fieldsList = [];
								// Update the fileds
								item.message = item?.message?.map((field) => {
									fieldsList.push(field.id);
									if (field.id === newFields[i].id) {
										field = newFields[i];
									}
									return field;
								});

								// Incase new filed is added
								if (!fieldsList.includes(newFields[i].id)) {
									item.message = [...item.message, newFields[i]];
									// Sort if index is present
									if (newFields[i].index) {
										item.message = item?.message.sort((a, b) =>
											a.index > b.index ? 1 : b.index > a.index ? -1 : 0
										);
									}
								}
							}
							break;
						}
					}
					// if the any changes happens
					// The reason for checking undefined because we don't want to change the value which is previously set
					if (promptOnClose !== undefined)
						item.options.promptOnClose = promptOnClose;
				}
			}
		});
	}
	return NonConversationalData;
};

export const handleMessageTypeForm2ResultAction = (
	NonConversationalData,
	message,
	promptOnClose
) => {
	if (
		NonConversationalData &&
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		NonConversationalData.components.forEach((item) => {
			if (
				item?.options?.controlId &&
				message?.options?.controlId &&
				item?.options?.controlId === message?.options?.controlId
			) {
				switch (item.messageType) {
					case MessageTypeConstants.MESSAGE_TYPE_FORM2:
						if (message.message.field) {
							item.message.map((field) => {
								if (field.id === message.message.field) {
									field.results = message.message.results;
									field.validationResult = message.message.validationResult;
									field.validationMessage = message.message.validationMessage;
								}
								return field;
							});
						} else {
							item.genericError = message.message;
							if (!message.message.validationResult) {
								store.dispatch(
									showSnackbarV2("error", message.message.validationMessage)
								);
							}
						}
						break;
				}
				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			} else if (
				item?.options?.controlId &&
				message?.options?.controlId &&
				item?.options?.controlId === message?.options?.parent
			) {
				// if the form is inside the conatiner
				item.message.map((msg) => {
					if (
						msg?.message?.options?.controlId === message?.options?.controlId
					) {
						msg.message.fields.map((field) => {
							if (field.id === message.message.field) {
								field.results = message.message.results;
								field.validationResult = message.message.validationResult;
								field.validationMessage = message.message.validationMessage;
							}
							return field;
						});
					}
				});

				// if the any changes happens
				// The reason for checking undefined because we don't want to change the value which is previously set
				if (promptOnClose !== undefined)
					item.options.promptOnClose = promptOnClose;
			}
		});
	}
	return NonConversationalData;
};
