import { MessageTypeConstants } from "../../../../Services/Message";
import {
	RENDER_MESSAGE_TYPE_TABLE,
	RENDER_MESSAGE_TYPE_CONTAINER,
	CHANGE_CONTAINER_SELECTED_TAB,
	RENDER_MESSAGE_TYPE_FORM2,
	RENDER_MESSAGE_TYPE_MENU,
	RENDER_MESSAGE_TYPE_CALENDAR,
	RENDER_MESSAGE_TYPE_MAP,
	RENDER_MESSAGE_TYPE_HTML,
	CHANGE_CALENDAR,
	RESET_FORM2_FIELDS,
	RESET_CONTAINER_FIELDS,
	REMOVE_APP,
	BG_CALENDAR_TOOLBAR,
	SAVE_MAP_SETTINGS,
	RENDER_MESSAGE_TYPE_VIDEOCALL,
	RENDER_CHANGE_NONCONVERSATION_SELECTED_TAB,
	RESET_NONCONVENTIONAL,
} from "./types";
import store from "../../../../State/configureStore";
import _ from "lodash";

import {
	handleMessageTypeNotification,
	handleMessageTypeNonVideoNotification,
} from "../../../Store/Notification/NotificationAction";
import {
	handleMessageTypeForm2ChangeAction,
	handleMessageTypeForm2ResultAction,
	handleMessageTypeTableChangeAction,
	handleMessageTypeInlineFormTableResultAction,
	handleMessageTypeTableChangeColumnTemplate,
} from "../NonConversationalHelpers/NonConversationalActionHelper";
import { renderNonconversationalFilter } from "../../../Store/NonConversationalFilter/NonConversationalFilterAction";

export const v2HandleNonConversationalServerMessages = (
	conversationId,
	message
) => (dispatch) => {
	switch (message.messageType) {
		case MessageTypeConstants.MESSAGE_TYPE_MENU:
			dispatch(handleMessageTypeMenu(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_TABLE:
			if (
				["changeFilter", "validationFilter", "resultsFilter"].includes(
					message.options.action
				)
			) {
				dispatch(renderNonconversationalFilter(message));
			} else {
				dispatch(handleMessageTypeTable(conversationId, message));
			}
			break;
		case MessageTypeConstants.MESSAGE_TYPE_MAP:
			if (
				["changeFilter", "validationFilter", "resultsFilter"].includes(
					message.options.action
				)
			) {
				dispatch(renderNonconversationalFilter(message));
			} else {
				dispatch(handleMessageTypeMap(conversationId, message));
			}
			break;
		case MessageTypeConstants.MESSAGE_TYPE_CONTAINER:
			dispatch(handleMessageTypeContainer(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_FORM2:
			dispatch(handleMessageTypeForm2(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
			if (
				["changeFilter", "validationFilter", "resultsFilter"].includes(
					message.options.action
				)
			) {
				dispatch(renderNonconversationalFilter(message));
			} else {
				dispatch(handleMessageTypeCalendar(conversationId, message));
			}
			break;
		case MessageTypeConstants.MESSAGE_TYPE_VIDEO_CALL:
			if (parseInt(message.contentType) === 600) {
				dispatch(handleMessageTypeNotification(conversationId, message));
			}
			break;
		case MessageTypeConstants.MESSAGE_TYPE_HTML:
			dispatch(handleMessageTypeHtml(conversationId, message));
			break;
		case MessageTypeConstants.MESSAGE_TYPE_STD_NOTIFICATION:
		case MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION:
		case MessageTypeConstants.MESSAGE_TYPE_AUTHORIZATION_REQUEST:
			dispatch(handleMessageTypeNonVideoNotification(conversationId, message));
			break;
	}
};

export const handleChangeNonConversationalMessagesTab = (
	conversationId,
	selectedTab
) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			NonConversationalData.selectedTab = selectedTab;
		} else {
			NonConversationalData = {
				...NonConversationalData,
				selectedTab: selectedTab,
				components: [],
			};
		}

		dispatch({
			type: RENDER_CHANGE_NONCONVERSATION_SELECTED_TAB,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeMenu = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			let componentsList = []; // Use to maintain the order of tabs in components
			NonConversationalData.components.forEach((item, i) => {
				componentsList.push(NonConversationalData.components[i].options.tabId);
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					if (message?.options?.pullDown) {
						NonConversationalData.pullDownMenuComponent = message;
					} else {
						NonConversationalData.components[i] = message;
						if (
							!message?.message?.options?.hasOwnProperty(
								"updateInBackground"
							) ||
							!message?.message?.options?.updateInBackground
						) {
							NonConversationalData.selectedTab =
								message?.message?.options?.tabId;
						}
					}
				} else if (message?.options?.pullDown) {
					NonConversationalData.pullDownMenuComponent = message;
				}
			});
			if (
				!componentsList.includes(message.options.tabId) &&
				!message?.options?.pullDown
			) {
				NonConversationalData.components.push(message);
			}
		} else {
			if (message?.options?.pullDown) {
				NonConversationalData = {
					...NonConversationalData,
					components: [],
					pullDownMenuComponent: message,
				};
			} else {
				if (
					!message?.message?.options.hasOwnProperty("updateInBackground") ||
					!message?.message?.options?.updateInBackground
				) {
					NonConversationalData = {
						...NonConversationalData,
						selectedTab: message?.message?.options?.tabId,
						components: [message],
					};
				} else {
					NonConversationalData = {
						...NonConversationalData,
						components: [message],
					};
				}
			}
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_MENU,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		let NonConversationalData = {};
		if (message?.options?.pullDown) {
			NonConversationalData = {
				components: [],
				pullDownMenuComponent: message,
			};
		} else {
			if (
				!message?.message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.message?.options?.updateInBackground
			) {
				NonConversationalData = {
					selectedTab: message?.message?.options?.tabId,
					components: [message],
				};
			} else {
				NonConversationalData = {
					components: [message],
				};
			}
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_MENU,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeVideoCall = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			let componentsList = []; // Use to maintain the order of tabs in components
			NonConversationalData.components.forEach((item, i) => {
				componentsList.push(NonConversationalData.components[i].options.tabId);
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					NonConversationalData.components[i] = message;
				}
			});
			if (!componentsList.includes(message.options.tabId)) {
				NonConversationalData.components.push(message);
			}
		} else {
			NonConversationalData = {
				...NonConversationalData,
				components: [message],
			};
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message?.options?.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_VIDEOCALL,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_VIDEOCALL,
				data: {
					[conversationId]: {
						selectedTab: message?.options?.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_VIDEOCALL,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const handleMessageTypeTable = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = [];
		NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					NonConversationalData = handleMessageTypeTableChangeAction(
						NonConversationalData,
						message,
						true
					);
					break;
				case "validation":
				case "results":
					NonConversationalData = handleMessageTypeInlineFormTableResultAction(
						NonConversationalData,
						message
					);
					break;
				case "changeColumnTemplate":
					NonConversationalData = handleMessageTypeTableChangeColumnTemplate(
						NonConversationalData,
						message
					);
					break;
			}
		} else {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let openDrawer, showFilterSearchInput, view, style, isNewFilter;
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("openDrawer")
				) {
					openDrawer = message.options.openDrawer;
				} else {
					openDrawer = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("showFilterSearchInput")
				) {
					showFilterSearchInput = message.options.showFilterSearchInput;
				} else {
					showFilterSearchInput = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("view")
				) {
					view = message.options.view;
				} else {
					view = "month";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("style")
				) {
					style = message.options.style;
				} else {
					style = "calendar";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("isNewFilter")
				) {
					isNewFilter = message.options.isNewFilter;
				} else {
					isNewFilter = false;
				}

				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						item?.options?.tabId &&
						message?.options?.tabId &&
						item?.options?.tabId === message?.options?.tabId
					) {
						view = NonConversationalData.components[i].options?.view;
						style = NonConversationalData.components[i].options?.style;
						NonConversationalData.components[i] = message;
						NonConversationalData.components[i].options.view = view;
						NonConversationalData.components[i].options.style = style;
						NonConversationalData.components[i].options.openDrawer =
							NonConversationalData.components[i].options.openDrawer ||
							openDrawer;
						NonConversationalData.components[i].options.showFilterSearchInput =
							NonConversationalData.components[i].options
								.showFilterSearchInput || showFilterSearchInput;
						NonConversationalData.components[i].options.isNewFilter =
							NonConversationalData.components[i].options.isNewFilter ||
							isNewFilter;
						if (NonConversationalData.components[i].options.openDrawer) {
							dispatch(
								renderNonconversationalFilter(
									NonConversationalData.components[i]
								)
							);
						}
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					message.options.view = view;
					message.options.style = style;
					message.options.openDrawer = openDrawer;
					message.options.showFilterSearchInput = showFilterSearchInput;
					message.options.isNewFilter = isNewFilter;
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message?.options?.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_TABLE,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_TABLE,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_TABLE,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const handleMessageTypeCalendar = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = [];
		NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					NonConversationalData = handleMessageTypeTableChangeAction(
						NonConversationalData,
						message,
						true
					);
					break;
				case "changeColumnTemplate":
					NonConversationalData = handleMessageTypeTableChangeColumnTemplate(
						NonConversationalData,
						message
					);
					break;
			}
		} else {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let openDrawer, showFilterSearchInput, view, style, isNewFilter;
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("openDrawer")
				) {
					openDrawer = message.options.openDrawer;
				} else {
					openDrawer = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("showFilterSearchInput")
				) {
					showFilterSearchInput = message.options.showFilterSearchInput;
				} else {
					showFilterSearchInput = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("view")
				) {
					view = message.options.view;
				} else {
					view = "month";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("style")
				) {
					style = message.options.style;
				} else {
					style = "calendar";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("isNewFilter")
				) {
					isNewFilter = message.options.isNewFilter;
				} else {
					isNewFilter = false;
				}

				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						(item?.options?.tabId && message?.options?.tabId,
							item?.options?.tabId === message?.options?.tabId)
					) {
						view = NonConversationalData.components[i].options?.view;
						style = NonConversationalData.components[i].options?.style;
						NonConversationalData.components[i] = message;
						NonConversationalData.components[i].options.view = view;
						NonConversationalData.components[i].options.style = style;
						NonConversationalData.components[i].options.openDrawer =
							NonConversationalData.components[i].options.openDrawer ||
							openDrawer;
						NonConversationalData.components[i].options.showFilterSearchInput =
							NonConversationalData.components[i].options
								.showFilterSearchInput || showFilterSearchInput;
						NonConversationalData.components[i].options.isNewFilter =
							NonConversationalData.components[i].options.isNewFilter ||
							isNewFilter;
						if (NonConversationalData.components[i].options.openDrawer) {
							dispatch(
								renderNonconversationalFilter(
									NonConversationalData.components[i]
								)
							);
						}
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					message.options.view = view;
					message.options.style = style;
					message.options.openDrawer = openDrawer;
					message.options.showFilterSearchInput = showFilterSearchInput;
					message.options.isNewFilter = isNewFilter;
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message.options.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_CALENDAR,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_CALENDAR,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_CALENDAR,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const handleCalendarChange = (conversationId, options, field, value, parentTabId) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			NonConversationalData.components.forEach((item, i) => {
				if (
					item?.options?.tabId &&
					options?.tabId &&
					item?.options?.tabId === options?.tabId
				) {
					NonConversationalData.components[i].options[field] = value;
				}
				if (parentTabId === item?.options?.tabId) {
					item.message.forEach((itm) => {
						if (itm?.message?.options?.tabId === options?.tabId) {
							itm.message.options.style = value;
						}
					});
				}
			});
		}

		dispatch({
			type: CHANGE_CALENDAR,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleCalendarToolBarChange = (payload) => (dispatch) => {
	dispatch({
		type: BG_CALENDAR_TOOLBAR,
		data: {
			bgClndrToolBarState: payload,
		},
	});
};

export const handleMapSettingsChange = (payload) => (dispatch) => {
	dispatch({
		type: SAVE_MAP_SETTINGS,
		data: {
			mapSettingsState: payload,
		},
	});
};

export const handleMessageTypeMap = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = [];
		NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					NonConversationalData = handleMessageTypeTableChangeAction(
						NonConversationalData,
						message,
						true
					);
					break;
				case "changeColumnTemplate":
					NonConversationalData = handleMessageTypeTableChangeColumnTemplate(
						NonConversationalData,
						message
					);
					break;
			}
		} else {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let openDrawer, showFilterSearchInput, view, style, isNewFilter;
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("openDrawer")
				) {
					openDrawer = message.options.openDrawer;
				} else {
					openDrawer = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("showFilterSearchInput")
				) {
					showFilterSearchInput = message.options.showFilterSearchInput;
				} else {
					showFilterSearchInput = false;
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("view")
				) {
					view = message.options.view;
				} else {
					view = "month";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("style")
				) {
					style = message.options.style;
				} else {
					style = "map";
				}
				if (
					message &&
					message.options &&
					message.options.hasOwnProperty("isNewFilter")
				) {
					isNewFilter = message.options.isNewFilter;
				} else {
					isNewFilter = false;
				}

				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						(item?.options?.tabId && message?.options?.tabId,
							item?.options?.tabId === message?.options?.tabId)
					) {
						view = NonConversationalData.components[i].options?.view;
						style = NonConversationalData.components[i].options?.style;
						NonConversationalData.components[i] = message;
						NonConversationalData.components[i].options.view = view;
						NonConversationalData.components[i].options.style = style;
						NonConversationalData.components[i].options.openDrawer =
							NonConversationalData.components[i].options.openDrawer ||
							openDrawer;
						NonConversationalData.components[i].options.showFilterSearchInput =
							NonConversationalData.components[i].options
								.showFilterSearchInput || showFilterSearchInput;
						NonConversationalData.components[i].options.isNewFilter =
							NonConversationalData.components[i].options.isNewFilter ||
							isNewFilter;
						if (NonConversationalData.components[i].options.openDrawer) {
							dispatch(
								renderNonconversationalFilter(
									NonConversationalData.components[i]
								)
							);
						}
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					message.options.view = view;
					message.options.style = style;
					message.options.openDrawer = openDrawer;
					message.options.showFilterSearchInput = showFilterSearchInput;
					message.options.isNewFilter = isNewFilter;
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message.options.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_MAP,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_MAP,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_MAP,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const handleMessageTypeContainer = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			let conatinerSelectedTab = 0;
			let componentsList = []; // Use to maintain the order of tabs in components
			NonConversationalData.components.forEach((item, i) => {
				componentsList.push(NonConversationalData.components[i].options.tabId);
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					let styles = [];
					NonConversationalData.components[i].message.forEach(msg => {
						styles.push(msg.message.options.style);
					});
					conatinerSelectedTab = item.conatinerSelectedTab;
					NonConversationalData.components[i] = message;
					NonConversationalData.components[i].message.forEach((msg, index) => {
						msg.message.options.style = styles[index];
					});
					NonConversationalData.components[
						i
					].conatinerSelectedTab = conatinerSelectedTab;
				}
			});
			if (!componentsList.includes(message.options.tabId)) {
				NonConversationalData.components.push({
					...message,
					conatinerSelectedTab,
				});
			}
		} else {
			NonConversationalData = {
				...NonConversationalData,
				components: [{ ...message, conatinerSelectedTab: 0 }],
			};
		}
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			NonConversationalData.selectedTab = message?.options?.tabId;
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_CONTAINER,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_CONTAINER,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_CONTAINER,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const handleContainerTabChange = (
	conversationId,
	message,
	conatinerSelectedTab
) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			NonConversationalData.components.forEach((item, i) => {
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					NonConversationalData.components[
						i
					].conatinerSelectedTab = conatinerSelectedTab;
				}
			});
		}

		dispatch({
			type: CHANGE_CONTAINER_SELECTED_TAB,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const resetContainerFields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.components.length; i++) {
			if (
				NonConversationalData.components[i] &&
				NonConversationalData.components[i].options &&
				message?.options &&
				NonConversationalData.components[i].options.tabId ===
				message.options.tabId
			) {
				for (
					let j = 0;
					j < NonConversationalData.components[i].message.length;
					j++
				) {
					// check if it is form type
					if (
						NonConversationalData.components[i].message[j].type ===
						MessageTypeConstants.MESSAGE_TYPE_FORM2
					) {
						// reset for fields in forms
						for (
							let k = 0;
							k <
							NonConversationalData.components[i].message[j].message.fields
								.length;
							k++
						) {
							if (
								!NonConversationalData.components[i].message[j].message.fields[
									k
								].readOnly
							) {
								NonConversationalData.components[i].message[j].message.fields[
									k
								].value = "";
								if (
									NonConversationalData.components[i].message[j].message.fields[
										k
									].results &&
									!_.isEmpty(
										NonConversationalData.components[i].message[j].message
											.fields[k].results
									)
								) {
									NonConversationalData.components[i].message[j].message.fields[
										k
									].results = [];
								}
							}
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_CONTAINER_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeForm2 = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);
		// Handle if any change in form
		if (message?.options?.action) {
			switch (message?.options?.action) {
				case "change":
					NonConversationalData = handleMessageTypeForm2ChangeAction(
						NonConversationalData,
						message,
						true
					);
					break;
				case "validation":
				case "results":
					NonConversationalData = handleMessageTypeForm2ResultAction(
						NonConversationalData,
						message
					);
					break;
			}
		} else {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						item?.options?.tabId &&
						message?.options?.tabId &&
						item?.options?.tabId === message?.options?.tabId
					) {
						NonConversationalData.components[i] = message;
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				NonConversationalData.selectedTab = message?.options?.tabId;
			}
		}
		dispatch({
			type: RENDER_MESSAGE_TYPE_FORM2,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_FORM2,
				data: {
					[conversationId]: {
						selectedTab: message?.options?.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_FORM2,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const resetForm2Fields = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		// search for the message
		for (let i = 0; i < NonConversationalData.components.length; i++) {
			if (
				NonConversationalData.components[i] &&
				NonConversationalData.components[i].options &&
				message?.options &&
				NonConversationalData.components[i].options.tabId ===
				message.options.tabId
			) {
				// search for the non readonly field and reset the value
				for (
					let j = 0;
					j < NonConversationalData.components[i].message.length;
					j++
				) {
					if (!NonConversationalData.components[i].message[j].readOnly) {
						NonConversationalData.components[i].message[j].value = "";
						if (
							NonConversationalData.components[i].message[j].results &&
							!_.isEmpty(NonConversationalData.components[i].message[j].results)
						) {
							NonConversationalData.components[i].message[j].results = [];
						}
					}
				}
			}
		}

		dispatch({
			type: RESET_FORM2_FIELDS,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	}
};

export const handleMessageTypeHtml = (conversationId, message) => (
	dispatch
) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);
		if (!message.options.external) {
			if (
				NonConversationalData &&
				!_.isEmpty(NonConversationalData) &&
				!_.isEmpty(NonConversationalData.components)
			) {
				let componentsList = []; // Use to maintain the order of tabs in components
				NonConversationalData.components.forEach((item, i) => {
					componentsList.push(
						NonConversationalData.components[i].options.tabId
					);
					if (
						item?.options?.tabId &&
						message?.options?.tabId &&
						item?.options?.tabId === message?.options?.tabId
					) {
						NonConversationalData.components[i] = message;
					}
				});
				if (!componentsList.includes(message.options.tabId)) {
					NonConversationalData.components.push(message);
				}
			} else {
				NonConversationalData = {
					...NonConversationalData,
					components: [message],
				};
			}
			if (
				!message?.options?.hasOwnProperty("updateInBackground") ||
				!message?.options?.updateInBackground
			) {
				NonConversationalData.selectedTab = message?.options?.tabId;
			}
		} else {
			window.open(message.options.url, "_blank");
		}

		dispatch({
			type: RENDER_MESSAGE_TYPE_HTML,
			data: {
				[conversationId]: NonConversationalData,
			},
		});
	} else {
		if (
			!message?.options?.hasOwnProperty("updateInBackground") ||
			!message?.options?.updateInBackground
		) {
			dispatch({
				type: RENDER_MESSAGE_TYPE_HTML,
				data: {
					[conversationId]: {
						selectedTab: message.options.tabId,
						components: [message],
					},
				},
			});
		} else {
			dispatch({
				type: RENDER_MESSAGE_TYPE_HTML,
				data: {
					[conversationId]: {
						components: [message],
					},
				},
			});
		}
	}
};

export const removeNonConversationalMessages = (conversationId, message) => (
	dispatch
) => {
	let NonConversationalData = _.cloneDeep(
		store.getState().v2.NonConversational[conversationId]
	);

	if (
		!_.isEmpty(NonConversationalData) &&
		!_.isEmpty(NonConversationalData.components)
	) {
		NonConversationalData.components.forEach((item, i) => {
			if (
				item?.options?.tabId &&
				message?.options?.tabId &&
				item?.options?.tabId === message?.options?.tabId
			) {
				NonConversationalData.selectedTab = NonConversationalData.components[
					i - 1
				]
					? NonConversationalData.components[i - 1].options.tabId
					: "";
				NonConversationalData.components.splice(i, 1);
			}
		});
	}

	dispatch({
		type: REMOVE_APP,
		data: {
			[conversationId]: NonConversationalData,
		},
	});
};

export const resetNonConversational = () => (dispatch) => {
	dispatch({
		type: RESET_NONCONVENTIONAL,
		data: {},
	});
};

export const updateFilterFlags = (conversationId, message) => (dispatch) => {
	if (
		store.getState().v2.NonConversational &&
		!_.isEmpty(store.getState().v2.NonConversational)
	) {
		let NonConversationalData = _.cloneDeep(
			store.getState().v2.NonConversational[conversationId]
		);

		if (
			NonConversationalData &&
			!_.isEmpty(NonConversationalData) &&
			!_.isEmpty(NonConversationalData.components)
		) {
			NonConversationalData.components.forEach((item) => {
				if (
					item?.options?.tabId &&
					message?.options?.tabId &&
					item?.options?.tabId === message?.options?.tabId
				) {
					let newMessage = _.cloneDeep(item);
					newMessage.options = message.options;
					switch (item.messageType) {
						case MessageTypeConstants.MESSAGE_TYPE_CALENDAR:
							dispatch(handleMessageTypeCalendar(conversationId, newMessage));
							break;
						case MessageTypeConstants.MESSAGE_TYPE_MAP:
							dispatch(handleMessageTypeTable(conversationId, newMessage));
							break;
						case MessageTypeConstants.MESSAGE_TYPE_TABLE:
							dispatch(handleMessageTypeTable(conversationId, newMessage));
							break;
					}
				}
				if (message?.options?.parentTabId === item?.options?.tabId) {
					item.message.forEach((itm) => {
						if (itm?.message?.options?.tabId === message?.options?.tabId) {
							itm.message.options.view = message.options.view;
							itm.message.options.style = message.options.style;
							itm.message.options.openDrawer = message.options.openDrawer;
							itm.message.options.showFilterSearchInput =
								message.options.showFilterSearchInput;
							itm.message.options.isNewFilter = message.options.isNewFilter;
							itm.message.options.parentTabId = item?.options?.tabId;
							if (itm.message.options.openDrawer) {
								dispatch(renderNonconversationalFilter(itm.message));
							}
						}
					});
					let newMessageContainer = _.cloneDeep(item);
					dispatch(
						handleMessageTypeContainer(conversationId, newMessageContainer)
					);
				}
			});
		}
	}
};
